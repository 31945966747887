import { MouseEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import FilePreview from 'components/ProductSide/FilePreview';
import Box from 'components/Box/Box';

import { useProductsStore } from 'hooks/useStore';
import Toolbox from './Toolbox';
import Editor from './Editor';
import { ISideWithAsset } from 'types/types';
import { useEditor } from './useEditor';

const SFilename = styled.h5`
  color: #333;
  font-size: 14px;
  font-weight: 800;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
`;

type Props = ISideWithAsset & {
  productIndex: number;
  sideIndex: number;
};

export const ProductSide = ({
  assetId,
  id: sideId,
  asset,
  actions,
  productIndex,
  sideIndex,
}: Props) => {
  const updateSide = useProductsStore((state) => state.updateSide);
  const [isOpen, setIsOpen] = useState(false);

  const handlePreview = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  const {
    generatedPdf,
    handleFlip,
    handleRotate,
    changePlace,
    changeType,
    newActions,
    originalPdf,
  } = useEditor({
    sideId,
    assetId,
    fileUrl: asset.previewFile,
    actions,
    dimensions: asset.dimensions,
  });

  const updateProductSide = useCallback(async () => {
    await updateSide({ productIndex, sideIndex, actions: newActions });
  }, [productIndex, sideIndex, newActions, updateSide]);

  useEffect(() => {
    updateProductSide();
  }, [updateProductSide]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <FilePreview file={generatedPdf || originalPdf} loading={false} onClick={handlePreview} />
      </Box>
      <Toolbox
        onRotate={(direction) => handleRotate(direction)}
        onFlip={handleFlip}
        productIndex={productIndex}
        sideIndex={sideIndex}
        onPreview={() => setIsOpen(true)}
      />
      <Box mt="4px">
        <SFilename>{asset?.filename}</SFilename>
      </Box>
      {originalPdf && (
        <Editor
          isOpen={isOpen}
          pdfFile={generatedPdf}
          originalPdfFile={originalPdf}
          actions={newActions}
          width={asset.dimensions.width}
          height={asset.dimensions.width}
          onClose={() => setIsOpen(false)}
          onChangePlace={changePlace}
          onChangeType={changeType}
          onFlip={handleFlip}
          onRotate={handleRotate}
        />
      )}
    </>
  );
};
