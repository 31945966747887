import axios from 'axios';
import { useState } from 'react';
import { Upload, Progress, Alert } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';

import Box from 'components/Box/Box';
import useQueryParams from 'hooks/query-params';
import { useTranslation } from 'react-i18next';
import API from 'services/API';
import { useLayoutStore } from 'hooks/useStore';

const AddAsset = () => {
  const { t } = useTranslation();
  const { sessionId } = useQueryParams();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const setVisible = useLayoutStore((state) => state.setShouldDisplayAssetsProcessing);

  const onChange: UploadProps<File>['onChange'] = async ({ file: { originFileObj } }) => {
    try {
      setIsUploading(true);
      const file = originFileObj as RcFile;
      const signedUrl = await API.getSignedUrl(sessionId, file);
      if (!signedUrl) throw new Error('Failed to get signed url');

      const response = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progressPercent);
        },
      });

      if (response.status !== 200) throw new Error('Upload failed');
      await API.postNewUploadMessage(sessionId, file);
      setVisible(true);
    } catch (error) {
      setError('Upload failed');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box mt={2}>
      {error && <Alert message={error} type="error" />}
      {!isUploading && (
        <Upload.Dragger onChange={onChange} multiple={true} accept=".pdf,.jpg,.jpeg,.png">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('Drag file or click to upload')}</p>
        </Upload.Dragger>
      )}

      {isUploading && (
        <>
          <Progress percent={uploadProgress} status="active" />
          <h5 style={{ textAlign: 'center' }}>Uploading</h5>
        </>
      )}
    </Box>
  );
};

export default AddAsset;
