import ZoomInOutlined from '@ant-design/icons/ZoomInOutlined';
import Box from 'components/Box/Box';

import {
  DeleteOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useProductsStore } from 'hooks/useStore';
import { RotationDirection } from 'types/types';

type Props = {
  onRotate: (direction: RotationDirection) => void;
  onFlip: () => void;
  onPreview: () => void;
  productIndex: number;
  sideIndex: number;
};

const iconStyle = {
  fontSize: 20,
  cursor: 'pointer',
  alignSelf: 'end',
  marginLeft: 5,
};

const Toolbox = ({ onRotate, onFlip, onPreview, productIndex, sideIndex }: Props) => {
  const { t } = useTranslation();
  const deleteProductSide = useProductsStore((state) => state.deleteProductSide);
  return (
    <Box display="flex" justifyContent="space-between" marginTop="1rem">
      <Tooltip placement="left" title={t('SideActions.DeleteImage')}>
        <DeleteOutlined
          style={{ fontSize: 20, cursor: 'pointer' }}
          onClick={() => deleteProductSide(productIndex, sideIndex)}
        />
      </Tooltip>
      <Box display="flex" justifyContent={'space-between'} alignItems="center">
        <Tooltip placement="top" title={t('SideActions.RotateRight')}>
          <RotateRightOutlined style={iconStyle} onClick={() => onRotate('right')} />
        </Tooltip>
        <Tooltip placement="top" title={t('SideActions.RotateLeft')}>
          <RotateLeftOutlined style={iconStyle} onClick={() => onRotate('left')} />
        </Tooltip>
        <Tooltip placement="top" title={t('SideActions.FlipHorizontal')}>
          <SwapOutlined style={iconStyle} onClick={onFlip} />
        </Tooltip>
      </Box>

      <Tooltip placement="right" title={t('SideActions.PreviewAndMoreActions')}>
        <ZoomInOutlined style={iconStyle} onClick={onPreview} />
      </Tooltip>
    </Box>
  );
};

export default Toolbox;
