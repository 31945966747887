import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { Col } from 'antd';

import ProductCard from 'components/ProductCard/ProductCard';
import API from 'services/API';
import Box from 'components/Box/Box';

import { useProductsStore, useSessionStore } from 'hooks/useStore';

const getColsBySidesCount = (sidesCount: number) => {
  if (sidesCount === 1) {
    return 8;
  }
  if (sidesCount === 2) {
    return 12;
  }
  return 24;
};

function Products() {
  const sessionId = useSessionStore((state) => state.sessionId);
  const { products, setProducts } = useProductsStore((state) => {
    return {
      products: state.products,
      setProducts: state.setProducts,
    };
  });

  const { error, isLoading, data } = useQuery('products', () => API.getProducts(sessionId), {
    enabled: !!sessionId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const config = useSessionStore((state) => state.config);

  useEffect(() => {
    if (data) {
      setProducts(data);
    }
  }, [data, setProducts]);

  // unsubscribe when the component unmounts
  useEffect(() => {
    if (!sessionId) return;
    const { update, cancel } = API.updateProducts(sessionId, products);
    update();
    return () => {
      cancel();
    };
  }, [products, sessionId]);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  if (error) {
    return null;
  }

  if (!products.length) {
    return <Box>No products</Box>;
  }

  return (
    <>
      {products.map((product, index) => {
        return (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            md={{ span: getColsBySidesCount(config.sidesCount) }}
            key={index}
          >
            <ProductCard key={product.id} productIndex={index} product={product} />
          </Col>
        );
      })}
    </>
  );
}

export default Products;
