import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';

import Box from 'components/Box/Box';
import Counter from 'components/ProductCard/Counter';
import { useTranslation } from 'react-i18next';
import { useProductsStore } from 'hooks/useStore';

const ProductHeader = ({ productIndex }: { productIndex: number }) => {
  const { t } = useTranslation();
  const productsStore = useProductsStore((state) => state);
  const count = productsStore.products[productIndex].count;
  const productNumber = productIndex + 1;
  const productName = `Product ${productNumber}`;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={'1rem'}>
      <Typography.Title level={5}>{productName}</Typography.Title>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Counter
          count={count}
          productIndex={productIndex}
          onIncrement={() => productsStore.incrementProductCount(productIndex)}
          onDecrement={() => productsStore.decrementProductCount(productIndex)}
        />
        <Dropdown
          menu={{
            items: [
              {
                key: 'delete',
                onClick: () => productsStore.deleteProduct(productIndex),
                label: t('Delete'),
              },
            ],
          }}
          trigger={['click']}
        >
          <MoreOutlined
            style={{ fontSize: 24, cursor: 'pointer', marginLeft: '1rem' }}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>
      </Box>
    </Box>
  );
};

export default ProductHeader;
