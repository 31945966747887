import { FC, useEffect, useState } from 'react';
import { Alert, Progress } from 'antd';

import { useLayoutStore, useSessionStore } from 'hooks/useStore';
import useWebSocket from 'react-use-websocket';
import { useQueryClient } from 'react-query';

const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL || 'ws://localhost:8080';

const RealTimeAssetsProcessing: FC = () => {
  const sessionId = useSessionStore((state) => state.sessionId);
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useLayoutStore((state) => [
    state.shouldDisplayAssetsProcessing,
    state.setShouldDisplayAssetsProcessing,
  ]);

  const { readyState, lastMessage } = useWebSocket(`${WEB_SOCKET_URL}/${sessionId}/assets/status`);
  const isVisible = (readyState === 1 || readyState === 2) && visible;

  useEffect(() => {
    if (lastMessage) {
      const { data } = lastMessage;
      const { percentage, status, error } = JSON.parse(data) as { percentage: number; status: string, error?: string };
      setVisible(true);
      if (status === 'error') setError(error || 'Fail to process pdf file');
      setProgress(percentage);
    }
  }, [lastMessage, setVisible]);

  useEffect(() => {
    const hasProcessed = progress === 100;
    if (!hasProcessed) return;
    const timeOut = setTimeout(() => {
      queryClient.invalidateQueries('assets');
      setProgress(0)
      setVisible(false);
    }, 2500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [progress, setVisible, queryClient]);

  // reset error after 5 seconds
  useEffect(() => {
    if (!error) return;
    const timeOut = setTimeout(() => {
      setError('');
      setVisible(false);
    }
    , 5000);
    return () => {
      clearTimeout(timeOut);
    }
  }
  , [error, setVisible]);


  if (!isVisible || !lastMessage) return null;

  if (error) return <Alert message={error} type="error" showIcon closable />;

  return (
    <>
      <Progress percent={progress} strokeColor={{ from: '#108ee9', to: '#87d068' }} />
      <h5 style={{ textAlign: 'center' }}>Assets Processing</h5>
    </>
  );
};

export default RealTimeAssetsProcessing;
