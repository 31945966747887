import * as dateFns from 'date-fns';
import axios, { Canceler } from 'axios';
import { IAsset, IConfig, IProduct } from 'types/types';

const BASE_URL = process.env.REACT_APP_PRINT_API_URL;

type API_TYPE = {
  getConfig: (sessionId: string) => Promise<IConfig>;
  getProducts: (sessionId: string) => Promise<IProduct[]>;
  updateProducts: (
    sessionId: string,
    products: IProduct[],
  ) => {
    update: () => Promise<any>;
    cancel: Canceler;
  };
  getAssets: (sessionId: string) => Promise<IAsset[]>;
  getAssetsProcessingStatus: (sessionId: string) => Promise<any>;
  postResetAssetsProcessingStatus: (sessionId: string) => Promise<any>;
  deleteAsset: (sessionId: string, assetId: string) => Promise<any>;
  postConfirming: (sessionId: string) => Promise<any>;
  getSignedUrl: (sessionId: string, file: File) => Promise<string>;
  postNewUploadMessage: (sessionId: string, file: File) => Promise<any>;
};

const API: API_TYPE = {
  getConfig: async (sessionId) => {
    try {
      const { data: configData } = await axios.get<IConfig>(`${BASE_URL}/${sessionId}/config`);

      if (!configData) {
        throw new Error('Invalid or expired session');
      }
      return configData;
    } catch (e) {
      throw new Error('Invalid or expired session');
    }
  },
  getProducts: async (sessionId) => {
    const { data } = await axios.get<IProduct[]>(`${BASE_URL}/${sessionId}/products`);
    return data;
  },
  updateProducts: (sessionId, products) => {
    const cancelTokenSource = axios.CancelToken.source();
    return {
      update: () =>
        axios.post(`${BASE_URL}/${sessionId}/products`, products).then(({ data }) => {
          return data;
        }),
      cancel: cancelTokenSource.cancel,
    };
  },
  getAssets: (sessionId) => {
    return axios.get<IAsset[]>(`${BASE_URL}/${sessionId}/assets`).then(({ data: assets }) => {
      return assets.sort((assetA: IAsset, assetB: IAsset) => {
        const dateA = dateFns.parse(assetA.createdAt, 'yyyyMMdd:HHmmssSSS', new Date());
        const dateB = dateFns.parse(assetB.createdAt, 'yyyyMMdd:HHmmssSSS', new Date());
        return dateFns.compareDesc(dateA, dateB);
      });
    });
  },
  getAssetsProcessingStatus: (sessionId) => {
    return axios.get(`${BASE_URL}/${sessionId}/assets/status`).then(({ data }) => {
      return data;
    });
  },
  postResetAssetsProcessingStatus: (sessionId) => {
    return axios.post(`${BASE_URL}/${sessionId}/assets/status`, {
      status: 'idle',
    });
  },
  deleteAsset: (sessionId, assetId) => {
    return axios.delete(`${BASE_URL}/${sessionId}/assets/${assetId}`).then(({ data }) => {
      return data;
    });
  },
  postConfirming: (sessionId) => {
    return axios.post(`${BASE_URL}/confirm/${sessionId}`).then(({ data }) => {
      return data;
    });
  },
  getSignedUrl: async (sessionId: string, file: File) => {
    const {
      data: { signedUrl },
    } = await axios.post<{ signedUrl: string }>(
      `${process.env.REACT_APP_PRINT_API_URL}/uploads/signed-url`,
      {
        fileName: file.name,
        fileType: file.type,
        sessionId,
      },
    );
    return signedUrl;
  },
  postNewUploadMessage: (sessionId: string, file: File) => {
    return axios.post(`${process.env.REACT_APP_PRINT_API_URL}/messages`, {
      sessionId,
      messageType: 'NEW_UPLOAD',
      message: {
        file: {
          fileName: file.name,
          fileType: file.type,
        },
      },
    });
  },
};

export default API;
