import { pdfjs } from 'react-pdf';

import Uploader from 'components/Uploader/Uploader';
import GlobalStyle from 'styles/global';
import 'antd/dist/antd.min.css';
import 'styles/custom.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <Uploader />
        <Toaster />
      </QueryClientProvider>
    </>
  );
}

export default App;
