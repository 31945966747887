import { equals } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { downloadFile } from 'services/downloadService';

import { createPreviewPdf, flipPreviewPDF } from 'utils/pdf';
import { FileManipulationOptions, RotationDirection, ISideWithAsset } from 'types/types';
import { useSessionStore } from 'hooks/useStore';

type IUseEditor = {
  fileUrl: string;
  actions: ISideWithAsset['actions'];
  dimensions: ISideWithAsset['asset']['dimensions'];
  sideId: ISideWithAsset['id'];
  assetId: ISideWithAsset['assetId'];
};

export const useEditor = ({ fileUrl, actions, dimensions, sideId, assetId }: IUseEditor) => {
  const [pdfFile, setPdfFile] = useState<Blob | undefined>(undefined);
  const [generatedPdf, setGeneratedPdf] = useState<Blob | undefined>(undefined);
  const config = useSessionStore((state) => state.config);
  const [newActions, setNewActions] = useState<ISideWithAsset['actions']>(actions);

  const generate = useCallback(async () => {
    if (!pdfFile) return;
    const file = await createPreviewPdf(pdfFile, {
      ...config,
      dimensions,
      ...newActions,
    } as FileManipulationOptions);
    return file;
  }, [newActions, pdfFile]);

  const handleRotate = async (direction: RotationDirection) => {
    let degree = direction === 'right' ? 90 : -90;
    degree = actions.rotation + degree;
    setNewActions((prev) => ({ ...prev, rotation: degree }));
  };

  const handleFlip = useCallback(async () => {
    const isFlip = !actions.flip;
    const file = await flipPreviewPDF(pdfFile);
    setGeneratedPdf(file);
    setNewActions((prev) => ({ ...prev, flip: isFlip }));
  }, [actions.flip, pdfFile]);

  // download pdf from url and set it to pdfFile
  useEffect(() => {
    if (fileUrl && !pdfFile) {
      downloadFile(fileUrl)
        .then(async (file) => {
          setPdfFile(file);
          await generate();
        })
        .catch((e) => {
          console.error('Error while downloading the file', e);
        });
    }
  }, [fileUrl, pdfFile, assetId, generate]);

  // generate new pdf when actions change
  useEffect(() => {
    //if (!pdfFile) return;
    // prevent generating pdf when actions are not changed
    const generatePdf = async () => {
      try {
        const file = await generate();
        if (!file) return;
        setGeneratedPdf(file);
      } catch (e) {
        console.error('Error while generating the file', e);
      }
    };
    generatePdf();
  }, [generate]);

  return {
    handleRotate,
    handleFlip,
    originalPdf: pdfFile,
    generatedPdf,
    newActions,
    changePlace: (place: ISideWithAsset['actions']['place']) => {
      setNewActions((prev) => ({ ...prev, place }));
    },
    changeType: (type: ISideWithAsset['actions']['type']) => {
      setNewActions((prev) => ({ ...prev, type }));
    },
  };
};
