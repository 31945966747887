import { FC, useEffect } from 'react';
import { Card, Row, Col, Skeleton } from 'antd';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Asset from 'components/Assets/Asset';
import Box from 'components/Box/Box';

import { IAsset } from 'types/types';
import API from 'services/API';
import useQueryParams from 'hooks/query-params';
import { useAssetsStore, useSessionStore } from 'hooks/useStore';
import useAutomateUserWorkflow from 'hooks/useAutomateUserWorkflow';
import AddAsset from './AddAsset';
import RealTimeAssetsProcessing from './RealTimeAssetsProcessing';

const Assets: FC = () => {
  const { t } = useTranslation();
  const { sessionId } = useQueryParams();
  const config = useSessionStore((state) => state.config);
  const setAssets = useAssetsStore((state) => state.setAssets);

  const {
    data: assets,
    isLoading,
    isRefetching,
    isError,
    error,
  } = useQuery<IAsset[]>('assets', () => API.getAssets(sessionId), {
    enabled: !!sessionId,
    // refetchInterval: 6000,
    onSuccess: (data) => {
      setAssets(data);
    },
  });

  const { automateUserWorkflowInSingleSide, shouldSkipAutomateWorkflow } =
    useAutomateUserWorkflow();

  useEffect(() => {
    if (shouldSkipAutomateWorkflow) return;
    automateUserWorkflowInSingleSide();
  }, [assets, automateUserWorkflowInSingleSide, shouldSkipAutomateWorkflow]);

  const instructions = t('Instructions', {
    returnObjects: true,
  }) as string[];

  const instructionsForOneProduct = instructions.slice(2, 5);
  const isLoadingAssets = isLoading;

  if (isError || error) return <Box>Errors when getting assets</Box>;
  if (isLoadingAssets) return <Skeleton active />;

  return (
    <>
      <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
        <RealTimeAssetsProcessing />
        <AddAsset />
        <Row gutter={8} style={{ marginTop: '1rem' }}>
          {isRefetching && (
            <Col className="gutter-row" span={12}>
              <Skeleton.Image active style={{ height: '124px', width: '90px' }} />
            </Col>
          )}
          {assets?.length === 0
            ? null
            : assets?.map((asset) => (
                <Col key={asset.id} className="gutter-row" span={12}>
                  <Asset assetId={asset.id} previewImage={asset.previewImage} />
                </Col>
              ))}
        </Row>
        <Box color="#6b7280" textAlign="left" style={{ marginTop: '1rem' }}>
          {/* Drag and drop assets to the product */}
          <h4>{t('Explanation')}</h4>
          <ul>
            {config.quantity > 1
              ? instructions.map((instruction, index) => (
                  <li
                    key={index}
                    style={{
                      marginBottom: '0.5rem',
                    }}
                  >
                    {instruction}
                  </li>
                ))
              : instructionsForOneProduct.map((instruction, index) => (
                  <li
                    key={index}
                    style={{
                      marginBottom: '0.5rem',
                    }}
                  >
                    {instruction}
                  </li>
                ))}
          </ul>
        </Box>
      </Card>
    </>
  );
};

export default Assets;
