import PlusOutlined from '@ant-design/icons/PlusOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import styled from 'styled-components';
import { useProductsStore } from 'hooks/useStore';

export const SContainer = styled.main`
  padding: 0.1rem;
`;

const SContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SCount = styled.input`
  width: 35px;
  height: 35px;
  padding: 0.2rem;
  font-size: 20px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin: 0 0.4rem;
  text-align: center;
`;

const styles = {
  icons: { fontSize: 15, cursor: 'pointer' },
};

type Props = {
  count: number;
  onIncrement: () => void;
  onDecrement: () => void;
  productIndex: number;
};

const Counter = ({ count, onIncrement, onDecrement, productIndex }: Props) => {
  const changeProductCount = useProductsStore((state) => state.changeProductCount);
  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value);
    changeProductCount(productIndex, parsedValue);
  };
  return (
    <SContainer>
      <SContent>
        <MinusOutlined style={styles.icons} onClick={onDecrement} />
        <SCount onChange={handleCountChange} type="number" value={count} min={0} />
        <PlusOutlined style={styles.icons} onClick={onIncrement} />
      </SContent>
    </SContainer>
  );
};

export default Counter;
