import { useState, useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin, Button, notification, Result } from 'antd';
import { useQuery } from 'react-query';

import Assets from 'components/Assets/Assets';
import Box from 'components/Box/Box';
import API from 'services/API';
import useQueryParams from 'hooks/query-params';
import { SContainer, SLoaderContainer, SSticky } from './Uploader.styles';
import { createEmptyProduct } from 'utils/misc';
import { useTranslation } from 'react-i18next';
import { useProductsStore, useSessionStore, useLayoutStore } from 'hooks/useStore';
import Products from 'components/Products/Products';

const Uploader = () => {
  const { sessionId } = useQueryParams();
  const { t } = useTranslation();
  const [isConfirming, setIsConfirming] = useState(false);

  const { products, totalProductsCount, addProduct } = useProductsStore((state) => {
    return {
      products: state.products,
      totalProductsCount: state.totalProductsCount,
      addProduct: state.addProduct,
    };
  });

  const { config, setSessionId, setConfig } = useSessionStore((state) => {
    return {
      config: state.config,
      setSessionId: state.setSessionId,
      setConfig: state.setConfig,
    };
  });
  const shouldDisplayAddProduct = useLayoutStore((state) => state.shouldDisplayAddProduct);

  const {
    isLoading,
    error: ConfigError,
    isError: isConfigError,
  } = useQuery('config', () => API.getConfig(sessionId), {
    enabled: !!sessionId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setSessionId(sessionId);
      setConfig(data);
    },
  });

  const validateConfirmation = useCallback(() => {
    const hasNoProducts = products.length === 0;

    // Validate the product count.
    const missingProduct = config?.quantity > totalProductsCount;
    if (hasNoProducts || missingProduct) {
      throw new Error(t('ConfirmValidation.MissingProduct') || 'Error: Missing product.');
    }

    // Validate the asset count.
    const hasNoAssets = products.every((product) => product.sides.every((side) => !side.assetId));
    const missingAsset = products.some((product) => product.sides.some((side) => !side.assetId));

    if (hasNoAssets || missingAsset) {
      throw new Error(t('ConfirmValidation.MissingAsset') || 'Error: Missing asset.');
    }
  }, [products, config?.quantity, totalProductsCount]);

  const handleConfirm = useCallback(async () => {
    try {
      validateConfirmation();
      setIsConfirming(true);
      const { message = '' } = await API.postConfirming(sessionId);
      if (message !== 'success') {
        throw new Error(t('ConfirmValidation.ApiError') || 'Error: API error.');
      }
      notification.open({
        message: t('SuccessConfirmation.Message'),
        description: t('SuccessConfirmation.Desc'),
        duration: 5,
        type: 'success',
      });
    } catch (error: any) {
      notification.open({
        message: t('Error'),
        description: error?.message,
        duration: 5,
        type: 'error',
      });
    } finally {
      setIsConfirming(false);
    }
  }, [validateConfirmation, sessionId, t]);

  if (isLoading) {
    return (
      <SLoaderContainer>
        <Spin size="large" />
      </SLoaderContainer>
    );
  }

  if (isConfigError || ConfigError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Send an Email</Button>}
      />
    );
  }

  return (
    <SContainer>
      <Box>
        <SSticky>
          <Box display="flex" alignContent={'center'} justifyContent={'space-between'}>
            <Box>
              <h3>
                {t('Allocated Product')} {totalProductsCount} / {config?.quantity}
              </h3>
            </Box>
            <Box>
              <Button
                type="primary"
                size="middle"
                onClick={handleConfirm}
                loading={isConfirming}
                style={{ background: '#eab308', borderColor: '#eab308' }}
              >
                {t('Check Files')}
              </Button>
            </Box>
          </Box>
        </SSticky>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 6 }}>
            <Assets />
          </Col>
          <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 18 }}>
            <Card style={{ overflow: 'auto', maxHeight: '100vh' }}>
              {shouldDisplayAddProduct && (
                <Button
                  type="primary"
                  size="middle"
                  style={{ marginBottom: '1rem' }}
                  onClick={() => addProduct(createEmptyProduct(null, config?.sidesCount))}
                  icon={<PlusOutlined />}
                >
                  {t('Add Product')}
                </Button>
              )}
              <Row className="gutter-row" gutter={[16, 16]}>
                <Products />
              </Row>
            </Card>
          </Col>
        </Row>
      </Box>
    </SContainer>
  );
};
export default Uploader;
