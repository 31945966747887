import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import translation files
import en from './locales/en.json';
import nl from './locales/nl.json';

// initialize react-i18next
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      nl: {
        translation: nl,
      },
    },
    lng: 'nl', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'htmlTag'],
    },
  });

export default i18n;
