import Box from 'components/Box/Box';

import { useTranslation } from 'react-i18next';
import { useLayoutStore, useProductsStore } from 'hooks/useStore';
import { RiDragDropLine } from 'react-icons/ri';

const overlayStyle = {
  background: 'rgba(0, 0, 0, 0.3)',
};

const EmptyProductSide = ({
  productIndex,
  sideIndex,
}: {
  productIndex: number;
  sideIndex: number;
}) => {
  const { t } = useTranslation();
  const layoutStore = useLayoutStore((state) => state);
  const selectAssetForSide = useProductsStore((state) => state.selectAssetForSide);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    layoutStore.setShouldHighlightDropArea(false);
  };

  const handleDrop = async function (
    event: React.DragEvent<HTMLDivElement>,
    {
      productIndex,
      sideIndex,
    }: {
      productIndex: number;
      sideIndex: number;
    },
  ) {
    event.preventDefault();
    const assetId = event.dataTransfer.getData('assetId');
    selectAssetForSide({ productIndex, sideIndex, assetId });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleDrop(e, { productIndex, sideIndex })}
      style={layoutStore.shouldHighlightDropArea ? overlayStyle : undefined}
    >
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <RiDragDropLine size={50} />
        <Box margin="0.5rem 0" textAlign={'center'}>
          {t('Drag and drop an image here')}
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyProductSide;
