import { useRef, useState, useCallback, MouseEvent } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Document, Page } from 'react-pdf';

const padding = 50;

// Add types to this code
type Props = {
  file: Blob | undefined;
  loading: boolean;
  small?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

const FilePreview = ({ file, loading, small = true, onClick }: Props) => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const isLoadingFile = !file || loading;

  const handleRenderSuccess = useCallback((page: any) => {
    let [, , canvasWidth, canvasHeight] = page.view;
    canvasWidth = Math.abs(canvasWidth);
    canvasHeight = Math.abs(canvasHeight);
    const scale = canvasWidth / canvasHeight;
    const drawWidth = window.innerWidth - 300 - padding;
    const drawHeight = window.innerHeight - padding;

    let width = drawWidth;
    let height = width / scale;

    if (width > drawWidth || height > drawHeight) {
      height = drawHeight;
      width = height * scale;
    }

    setWidth(width);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      ref={ref}
      onClick={onClick}
    >
      {isLoadingFile ? (
        <ClipLoader color="#35b3ee" />
      ) : small ? (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} onRenderSuccess={handleRenderSuccess} width={150} height={150} />
        </Document>
      ) : (
        <Document file={file} loading={<ClipLoader color="#35b3ee" />}>
          <Page pageNumber={1} onRenderSuccess={handleRenderSuccess} width={width} />
        </Document>
      )}
    </div>
  );
};

export default FilePreview;
