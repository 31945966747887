import { useState, useEffect } from 'react';

const useQueryParams = () => {
  const [sessionId, setSessionId] = useState('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paramsFromUrl = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (paramsFromUrl.sessionId) {
      setSessionId(paramsFromUrl.sessionId);
    }
  }, [paramsFromUrl]);

  return {
    sessionId,
  } as { sessionId: string };
};

export default useQueryParams;
