import styled from 'styled-components';

import { useLayoutStore } from 'hooks/useStore';
import { ISide } from 'types/types';

interface AssetProps {
  previewImage: string;
  assetId: string;
}

export const SAssetPreview = styled.div`
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  padding: 2px;
  margin-bottom: 8px;
  height: 130px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
`;

const Asset = ({ previewImage, assetId }: AssetProps) => {
  const layoutStore = useLayoutStore((state) => state);
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, assetId: ISide['id']) => {
    event.dataTransfer.setData('assetId', assetId);
    layoutStore.setShouldHighlightDropArea(true);
  };

  return (
    <SAssetPreview onDragStart={(e) => onDragStart(e, assetId)}>
      <img src={previewImage} alt="assets" />
    </SAssetPreview>
  );
};

export default Asset;
