import axios from 'axios';

export const downloadFile = async (fileUrl: string, cb?: () => void) => {
  return downloadFileAbsoluteURL(fileUrl, cb);
};

const downloadFileAbsoluteURL = async (
  url: string,
  cb?: (param: Record<string, string | number>) => void,
): Promise<Blob> => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
    onDownloadProgress: (e) => {
      const value = 50 + Math.round((e.loaded * 100) / e.total / 2);
      cb &&
        cb({
          status: value === 100 ? 'done' : 'progress',
          value,
        });
    },
  });

  return response.data;
};
