import { useProductsStore, useSessionStore, useAssetsStore } from './useStore';
import { useCallback, useMemo, useRef } from 'react';

export default function useAutomateUserWorkflow() {
  const { products, selectAssetForSide } = useProductsStore((state) => {
    return {
      products: state.products,
      selectAssetForSide: state.selectAssetForSide,
    };
  });

  const config = useSessionStore((state) => state.config);
  const assets = useAssetsStore((state) => state.assets);
  const hasAutomatedWorkFlowRef = useRef(false);

  const automateUserWorkflowInSingleSide = useCallback(() => {
    const side = products[0]?.sides[0];
    if (!side || !side?.id) return;
    if (assets && !assets.length) return;
    const asset = assets[0];
    selectAssetForSide({
      assetId: asset.id,
      productIndex: 0,
      sideIndex: 0,
    });
    hasAutomatedWorkFlowRef.current = true;
  }, [assets, products, selectAssetForSide]);

  const shouldSkipAutomateWorkflow = useMemo(() => {
    if (hasAutomatedWorkFlowRef.current) return true; // workflow has run before, stop further automation
    if (!config?.sidesCount || config?.sidesCount > 1) return true; // skip if more than one side
    if (!products.length) return true; // no products, no need to automate or not fetched yet
    if (!assets.length) return true; // no assets, no need to automate or not fetched yet
    if (products[0]?.sides[0]?.assetId) return true; // side already has an asset
    return false;
  }, [config?.sidesCount, products, assets]);

  return {
    shouldSkipAutomateWorkflow,
    automateUserWorkflowInSingleSide,
  };
}
