import { useMemo } from 'react';
import { Card, Col, Row } from 'antd';
import { isNil } from 'ramda';
import styled from 'styled-components';

import { ProductSide } from 'components/ProductSide/ProductSide';
import { useSessionStore } from 'hooks/useStore';
import { IProduct, ISideWithAsset } from 'types/types';
import ProductHeader from './ProductHeader';
import EmptyProductSide from 'components/ProductSide/EmptyProductSide';
import Box from 'components/Box/Box';

export const SCard = styled(Card)`
  width: 100%;
  border-radius: 8px;
`;

export const SCardContent = styled(Box)`
  height: 265px;
  padding: 10px;
  justify-content: center;
  cursor: pointer;
`;

export const SDelete = styled(Box)`
  display: flex;
  justify-content: center;
`;

const getColsBySideCountsForLgScreen = (sidesCount: number) => {
  switch (sidesCount) {
    case 1:
      return 24;
    case 2:
      return 12;
    case 3:
      return 8;
    case 4:
      return 6;
    default:
      return 8;
  }
};

type Props = {
  product: IProduct;
  productIndex: number;
};

const ProductCard = ({ product, productIndex }: Props) => {
  const config = useSessionStore((state) => state.config);

  const sides = useMemo(() => {
    return product.sides || [];
  }, [product.sides]);

  return (
    <SCard>
      <ProductHeader productIndex={productIndex} />
      <Row gutter={[16, 16]}>
        {(sides || []).map((side, index) => (
          <Col
            className="gutter-row"
            lg={{ span: getColsBySideCountsForLgScreen(config?.sidesCount) }}
            key={index}
          >
            <Card key={side.id} bodyStyle={{ padding: 0 }}>
              <SCardContent>
                {isNil(side?.assetId) ? (
                  <EmptyProductSide productIndex={productIndex} sideIndex={index} />
                ) : (
                  <ProductSide
                    productIndex={productIndex}
                    sideIndex={index}
                    {...(side as ISideWithAsset)}
                  />
                )}
              </SCardContent>
            </Card>
          </Col>
        ))}
      </Row>
    </SCard>
  );
};

export default ProductCard;
