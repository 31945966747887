import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { IAsset, IConfig, IProduct, ISide } from 'types/types';
import { isEmpty } from 'ramda';

type SessionState = {
  sessionId: string;
  config: IConfig;
};

type SessionActions = {
  setSessionId: (sessionId: string) => void;
  setConfig: (config: IConfig) => void;
};

type LayoutState = {
  shouldDisplayAddProduct: boolean;
  shouldHighlightDropArea: boolean;
  shouldDisplayAssetsProcessing: boolean;
};

type LayoutActions = {
  setShouldHighlightDropArea: (shouldHighlightDropArea: boolean) => void;
  setShouldDisplayAddProduct: (shouldDisplayAddProduct: boolean) => void;
  setShouldDisplayAssetsProcessing: (shouldDisplayAssetsProcessing: boolean) => void;
};

type ProductsState = {
  products: IProduct[] | [];
  totalProductsCount: number;
};

type AssetsState = {
  assets: IAsset[];
};

type AssetsActions = {
  setAssets: (assets: IAsset[]) => void;
  getAsset: (assetId: string) => IAsset | undefined;
};

type ProductsActions = {
  setProducts: (products: IProduct[]) => void;
  addProduct: (product: IProduct) => void;
  incrementProductCount: (productIndex: number) => void;
  decrementProductCount: (productIndex: number) => void;
  changeProductCount: (productIndex: number, newCount: number) => void;
  deleteProduct: (productIndex: number) => void;
  deleteProductSide: (productIndex: number, sideIndex: number) => void;
  addProductSide: (productId: string, side: ISide) => void;
  selectAssetForSide: (params: {
    assetId: string;
    sideIndex: number;
    productIndex: number;
  }) => void;
  updateSide: (params: {
    productIndex: number;
    sideIndex: number;
    actions: ISide['actions'];
  }) => Promise<void>;
};

const configInitialState: IConfig = {
  productSize: {
    width: '100',
    height: '100',
  },
  bleed: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  sidesCount: 1,
  quantity: 1,
  myId: 0,
  options: {
    showBleedMarker: true,
    minimalDpi: 72,
  },
};

const useSessionStore = create(
  subscribeWithSelector<SessionState & SessionActions>((set) => ({
    sessionId: '',
    config: configInitialState,
    setSessionId: (sessionId: string) => set({ sessionId }),
    setConfig: (config: IConfig) => set({ config }),
  })),
);

const useLayoutStore = create(
  subscribeWithSelector<LayoutState & LayoutActions>((set) => ({
    shouldDisplayAddProduct: true,
    shouldHighlightDropArea: false,
    setShouldDisplayAddProduct: (shouldDisplayAddProduct: boolean) =>
      set({ shouldDisplayAddProduct }),
    setShouldHighlightDropArea: (shouldHighlightDropArea: boolean) =>
      set({ shouldHighlightDropArea }),
    shouldDisplayAssetsProcessing: false,
    setShouldDisplayAssetsProcessing: (shouldDisplayAssetsProcessing: boolean) =>
      set({ shouldDisplayAssetsProcessing }),
  })),
);

const useAssetsStore = create(
  subscribeWithSelector<AssetsState & AssetsActions>((set, get) => ({
    assets: [],
    setAssets: (assets: IAsset[]) => set({ assets }),
    getAsset: (assetId: string) => {
      const { assets } = get();
      return assets.find((a) => a.id === assetId);
    },
  })),
);
function getTotalProductsCount(products: IProduct[]) {
  return products.reduce((acc, p) => acc + p.count, 0);
}

const useProductsStore = create(
  subscribeWithSelector<ProductsState & ProductsActions>((set, get) => ({
    products: [],
    totalProductsCount: 0,
    setProducts: (products: IProduct[]) => {
      // const totalProductsCount = getTotalProductsCount(products);
      set({ products });
    },
    addProduct: (product: IProduct) =>
      set(({ products }) => ({ products: [...products, product] })),
    incrementProductCount: (productIndex: number) =>
      set(({ products }) => ({
        products: products.map((p, pIndex) =>
          pIndex === productIndex
            ? {
                ...p,
                count: p.count + 1,
              }
            : p,
        ),
      })),
    decrementProductCount: (productIndex: number) =>
      set(({ products }) => ({
        products: products.map((p, pIndex) =>
          pIndex === productIndex
            ? {
                ...p,
                count: p.count - 1,
              }
            : p,
        ),
      })),
    changeProductCount: (productIndex: number, newCount: number) =>
      set(({ products }) => ({
        products: products.map((p, pIndex) =>
          pIndex === productIndex
            ? {
                ...p,
                count: newCount,
              }
            : p,
        ),
      })),
    deleteProduct: (productIndex: number) =>
      set(({ products }) => ({
        products: products.filter((p, index) => index !== productIndex),
      })),
    deleteProductSide: (productIdx: number, sideIdx: number) =>
      set(({ products }) => ({
        products: products.map((p, pIndex) =>
          pIndex === productIdx
            ? {
                ...p,
                sides: p.sides.map((s, sIndex) =>
                  sIndex === sideIdx
                    ? {
                        ...s,
                        assetId: null,
                        asset: null,
                      }
                    : s,
                ),
              }
            : p,
        ),
      })),
    addProductSide: (productId: string, side: ISide) =>
      set(({ products }) => ({
        products: products.map((p) =>
          p.id === productId ? { ...p, sides: [...p.sides, side] } : p,
        ),
      })),
    selectAssetForSide: ({ assetId, sideIndex, productIndex }) => {
      const asset = useAssetsStore.getState().getAsset(assetId);
      set(({ products }) => ({
        products: products.map((p, pIndex) =>
          pIndex === productIndex
            ? {
                ...p,
                sides: p.sides.map((s, sIndex) =>
                  sIndex === sideIndex ? { ...s, assetId, asset } : s,
                ),
              }
            : p,
        ),
      }));
    },
    updateSide: async ({ productIndex, sideIndex, actions }) => {
      set(({ products }) => ({
        products: products.map((p, pIndex) =>
          pIndex === productIndex
            ? {
                ...p,
                sides: p.sides.map((s, sIndex) => (sIndex === sideIndex ? { ...s, actions } : s)),
              }
            : p,
        ),
      }));
    },
  })),
);

// totalProductsCount changes to update shouldDisplayAddProduct
useProductsStore.subscribe(
  (state) => state.products,
  (products) => {
    const config = useSessionStore.getState().config;
    if (!config || isEmpty(config)) return;
    const { quantity } = config;
    const totalProductsCount = getTotalProductsCount(products);
    useProductsStore.setState({ totalProductsCount });
    const shouldDisplayAddProduct = totalProductsCount < quantity;
    useLayoutStore.setState({ shouldDisplayAddProduct });
  },
);

export { useProductsStore, useLayoutStore, useSessionStore, useAssetsStore };
